/**
 * @license
 * MyFonts Webfont Build ID 3797987, 2019-08-16T07:07:19-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Torus-Thin by Paulo Goode
 * URL: https://www.myfonts.com/fonts/paulo-goode/torus/thin/
 *
 * Webfont: Torus-SemiBold by Paulo Goode
 * URL: https://www.myfonts.com/fonts/paulo-goode/torus/semi-bold/
 *
 * Webfont: Torus-Regular by Paulo Goode
 * URL: https://www.myfonts.com/fonts/paulo-goode/torus/regular/
 *
 * Webfont: Torus-Light by Paulo Goode
 * URL: https://www.myfonts.com/fonts/paulo-goode/torus/light/
 *
 * Webfont: Torus-Heavy by Paulo Goode
 * URL: https://www.myfonts.com/fonts/paulo-goode/torus/heavy/
 *
 * Webfont: Torus-Bold by Paulo Goode
 * URL: https://www.myfonts.com/fonts/paulo-goode/torus/bold/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3797987
 * Licensed pageviews: 100,000
 * Webfonts copyright: Copyright &#x00A9; 2017 by Paulo Goode. All rights reserved.
 *
 * © 2019 MyFonts Inc
 */

@font-face {
  // Torus-Thin
  font-family: "Torus";
  font-weight: 100;
  src: url("~@fonts/torus/Torus-Thin.otf");
}

@font-face {
  // Torus-SemiBold
  font-family: "Torus";
  font-weight: 600;
  src: url("~@fonts/torus/Torus-SemiBold.otf");
}

@font-face {
  // Torus-SemiBold, for default bold
  font-family: "Torus";
  font-weight: 700;
  src: url("~@fonts/torus/Torus-SemiBold.otf");
}

@font-face {
  // Torus-Regular
  font-family: "Torus";
  font-weight: 400;
  src: url("~@fonts/torus/Torus-Regular.otf");
}

@font-face {
  // Torus-Light
  font-family: "Torus";
  font-weight: 300;
  src: url("~@fonts/torus/Torus-Light.otf");
}

@font-face {
  // Torus-Heavy
  font-family: "Torus";
  font-weight: 900;
  src: url("~@fonts/torus/Torus-Heavy.otf");
}

@font-face {
  // Torus-Bold
  font-family: "Torus";
  font-weight: 800;
  src: url("~@fonts/torus/Torus-Bold.otf");
}
